.splash-screen {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.splash-screen .loading-dot {
	font-size: 100px;
	margin-top: -80px;
	position: relative;
	animation: ease-in-out infinite alternate;
	animation-name: run;
	animation-duration: 1.2s;
}

@keyframes run {
	0% {
		left: -90px;
		color: #eee;
	}
	50% {
		color: #666;
	}
	100% {
		left: 90px;
		color: #eee;
	}
}

.loader {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

img.loader {
	width: 50%;
	max-width: 200px;
	height: auto;
}