.game-block {
	margin: 0;
	padding: 0;
	width: 1.5em;
	height: 1.5em;
	border: 1px solid #ddd;
	background-size: contain;
}

.game-board {
	border-spacing: 0px;
}

.piece-i {
	background-color: #ec858b;
}

.piece-j {
	background-color: #f1b598;
}

.piece-l {
	background-color: #f8efae;
}

.piece-o {
	background-color: #b5a677;
}

.piece-s {
	background-color: #816e56;
}

.piece-t {
	background-color: #b77c72;
}

.piece-z {
	background-color: #e3be58;
}

.piece-preview {
	background-color: #eee;
}

.piece-o-0 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-o-0.png");
}

.piece-o-1 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-o-1.png");
}

.piece-o-2 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-o-2.png");
}

.piece-o-3 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-o-3.png");
}

.piece-i-0 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-i-0.png");
}

.piece-i-1 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-i-1.png");
}

.piece-i-2 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-i-2.png");
}

.piece-i-3 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-i-3.png");
}

.piece-j-0 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-j-0.png");
}

.piece-j-1 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-j-1.png");
}

.piece-j-2 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-j-2.png");
}

.piece-j-3 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-j-3.png");
}

.piece-l-0 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-l-0.png");
}

.piece-l-1 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-l-1.png");
}

.piece-l-2 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-l-2.png");
}

.piece-l-3 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-l-3.png");
}

.piece-s-0 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-s-0.png");
}

.piece-s-1 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-s-1.png");
}

.piece-s-2 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-s-2.png");
}

.piece-s-3 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-s-3.png");
}

.piece-t-0 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-t-0.png");
}

.piece-t-1 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-t-1.png");
}

.piece-t-2 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-t-2.png");
}

.piece-t-3 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-t-3.png");
}

.piece-z-0 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-z-0.png");
}

.piece-z-1 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-z-1.png");
}

.piece-z-2 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-z-2.png");
}

.piece-z-3 {
	background-image: url("https://assets.regoapp.io/img/tetris/piece-z-3.png");
}

.rotate-0 {
	transform: rotate(0deg);
}

.rotate-90 {
	transform: rotate(90deg);
}

.rotate-180 {
	transform: rotate(180deg);
}

.rotate-270 {
	transform: rotate(270deg);
}

@font-face {
	font-family: 'Futura';
	src: url('../fonts/FuturaBlack.woff2') format('woff2'),
		url('../fonts/FuturaBlack.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

.futura {
	font-family: "Futura" !important
}